
$mobileAndTablet: 'max-width: 769px';  /* This doesnt work.. https://stackoverflow.com/questions/40722882/css-native-variables-not-working-in-media-queries#40723269 */
$images-path: '../../../images/modules/mod_lifeheadermenu';

.lifeHeaderMenu {

	.marginForFixedMenu{
		@media screen and (max-width: 769px) {
			height: 0px!important;
		}
	}

	.navbar-default {
		background-color: transparent;
		margin-bottom: 0px;
		border: 0;
		border-radius: 0px;
		padding: 0px 20px 0px 20px;

		transition: all .2s ease-in;


		@media screen and (min-width: 769px) {	 //Just for TABLET and SMARTPHONE
			border-color: transparent;
			
			&.transparent-header-menu-hp{

			}
			&.nav_trasp{
				background-color: transparent!important;
				/*transition: background-color 1000ms linear;*/
				.navbar-collapse{
					ul{
						li{
							a{
								background-color: transparent!important;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 769px) { 	//Just for DESKTOP
			position: relative;
			height: auto!important;
		}

		ul{
			background-color: transparent;
			> li{
				a{
					/*color: inherit!important;*/
			        position: relative;
			        /*transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;*/
			        /*transition: all .3s ease-in;*/
			        /*transition: all .1s ease-in-out .1s;*/
			        transition: all .2s ease-in;
			      
					&:hover{
						/*&:before {
						    position: absolute;
						    z-index: -1;
						    background-color: black;
						    opacity: 0.3;
						    left: 0;
						    top: 0;
						    height: 100%;
						    width: 100%;
						    content: '';
						}*/
						/* aaaaa */
					}

				}
				&.active{
					a{
						/*background-color: #df840c!important;*/
						
						/*background-color: transparent!important;*/
						/*&:before {
						    position: absolute;
						    z-index: -1;
						    background-color: black;
						    opacity: 0.3;
						    left: 0;
						    top: 0;
						    height: 100%;
						    width: 100%;
						    content: '';
						}*/
						/*aaaaa */

					}
				}
				ul.dropdown-menu{
					width: 100%!important;
					margin: 0;
					padding: 0;
					li{
						a{
							padding: 0.6rem 1rem;
							box-sizing: border-box;
						}
					}
				}
				&.open{
					a{
						
						/*background-color: transparent!important;
						&:before {
						    position: absolute;
						    z-index: -1;
						    background-color: black;
						    opacity: 0.4;
						    left: 0;
						    top: 0;
						    height: 100%;
						    width: 100%;
						    content: '';
						}*/ /*aaaaa*/
					}


					ul.dropdown-menu{
						padding: 0;
						border: 0;
						/*width: 100%!important;*/

			        	li{
			        		a{
			        			
			        			/*background-color: transparent!important;
			        			&:before {
								    position: absolute;
								    z-index: -1;
								    background-color: black;
								    opacity: 0.3;
								    left: 0;
								    top: 0;
								    height: 100%;
								    width: 100%;
								    content: '';
								}
								&:hover{
									&:before {
										opacity: 0.6;
									}
								}*/
								 /*aaaa*/
			        		}
			        	}
		        	} 
				}
			}
			
			/* MOBILE MENU */
			&.sm-collapsible{
				margin-top: 0rem;
				li{
					a{
						line-height: 1.5rem!important;
						//border-bottom: 1px #e7e7e7 solid;
					}
				}
			}
		}
		a{
			&.navbar-brand{
				padding: 0;
				img{
					transition: height .4s ease, line-height .4s ease, margin .4s ease, padding .4s ease;
				}
				@media screen and (max-width: 769px) {
					float: none;
					img{
						/*height: 90px!important;*/
						margin: auto;
						width: 120px!important;
					}
				}
			}
		}

		.navbar-toggle{
			.icon-bar{
				background-color: white;
			}
			&:hover{
				background-color: #df840c!important;
			}
			&:focus{
				background-color: #df840c!important;
			}
			@media screen and (max-width: 769px) {
				position: absolute;
				top: 0rem;
				right: 0;
				&.sub-arrow{
					top: 0.60rem;
					margin-right: 0.3rem;
				}
			
			}
		} 

		.navbar-collapse{
			@media screen and (max-width: 769px) {
				margin-top: 3rem;

				border-color: transparent;
				box-shadow: none;
			}
		}

		.mod-languages{
			padding: 0px 10px 0 20px;
			display: inline-block;
    		float: right;
    		display: table;
			height: 100%;

			@media screen and (max-width: 769px) {
				height: 3rem
			}

			form{
				display: table-cell;
    			vertical-align: middle;
			}

    		@media screen and (max-width: 769px) {
    			/*float: left;*/
    			position: absolute;
    			padding-left: 0px;
    		}
		}

		&.transparent-header-menu-hp{
			/*background-color: transparent!important;*/
		}

	}	
}
/*
body{
	&.device-md, &.device-lg{
		.lifeHeaderMenu {
			.navbar-default {
				&.nav_trasp{
					background-color: transparent!important;
				}
			}
		}
	}
}
*/





$images-path: '../../../images/modules/mod_lifeheadermenu';

header{
	z-index: 2;
	&.lifeHeaderMenu{
		z-index: 3;
		.navbar-header{
			z-index: 4;
			.burgher{
				.burgher-background{
			        background-color: #111;
				    border-radius: 100px;
				    opacity: .5;
				    display: block;
				    width: 49px;
				    height: 49px;
				    position: fixed;
				    top: 24px;
				    right: 31px;
				    z-index: 6;
				}
				.toggle-button {
					z-index: 6;
				    position: fixed;
				    top: 15px;
				    right: 15px;
				    /*padding: 9px 10px;*/
				    margin: 8px 15px 8px 0;
				    height: 50px;
				    width: 50px;
				    padding: 16px 11px 10px 11px;
				    cursor: pointer;

				    &:hover {
						opacity: .7;
					}

					&.toggle-active{
						/*.bar {
						    background-color: #f08e0d;
						}*/
						.top {
						    /*-webkit-transform: translateY(6px) translateX(0) rotate(45deg);*/
						    transform: translateY(7px) translateX(0) rotate(45deg);
						}
						.middle {
						   opacity: 0;
						}
						.bottom {
						    /*-webkit-transform: translateY(-12px) translateX(0) rotate(-45deg);*/
						    transform: translateY(-7px) translateX(0) rotate(-45deg);
						}

						&.hover-red{ .bar {background-color: #F44336;}}
					    &.hover-pink{ .bar {background-color: #E91E63;}}
						&.hover-purple {.bar {background-color: #9C27B0;}}
						&.hover-deeppurple { .bar {background-color: #673AB7;}}
						&.hover-indigo { .bar {background-color: #3F51B5;}}
						&.hover-blue { .bar {background-color: #2196F3;}}
						&.hover-lightblue { .bar {background-color: #03A9F4;}}
						&.hover-cyan { .bar {background-color: #00BCD4;}}
						&.hover-teal { .bar {background-color: #009688;}}
						&.hover-green { .bar {background-color: #4CAF50;}}
						&.hover-lightgreen { .bar {background-color: #BBC34A;}}
						&.hover-lime { .bar {background-color: #CDDC39;}}
						&.hover-yellow { .bar {background-color: #FFEB3B;}}
						&.hover-amber { .bar {background-color: #FFC107;}}
						&.hover-orange { .bar {background-color: #FF9800;}}
						&.hover-deeporange { .bar {background-color: #FF5722;}}
						&.hover-brown { .bar {background-color: #795548;}}
						&.hover-grey { .bar {background-color: #9E9E9E;}}
						&.hover-bluegrey { .bar {background-color: #607D8B;}}
						&.hover-black { .bar {background-color: #000000;}}
					}

					.bar {
					    background-color: #FFFFFF;
					    display: block;
					    width: 100%;
					    height: 3px;
					    border: 0;
					    transition: all .35s ease;

					    &.middle {
							top: 11px;
						}
						&.bottom {
						    top: 22px;
						}
						& + .bar {
					    	margin-top: 4px;
						}
					}
				}
			}
		}
		
		nav{
			&.overlay {
			    position: fixed;
			    z-index: 5;

			    /*background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);*/
			    background-image: linear-gradient(120deg, #1f1f1f 0%, #3d3d3d 100%);
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 0;
			    visibility: hidden;
			    opacity: 0;
			    -webkit-transition: opacity .35s, visibility .35s, height .35s;

			    ul {
			    	&.menu{
			    		display: block;
					    position: relative;
					    /*font-family: 'Vollkorn', serif;*/
					    font-size: 1.6rem;
					    font-weight: 400;
					    text-align: center;
					    list-style: none;
					    padding: 0;
					    line-height: 3rem;

					    margin-top: 5rem;
						
						@media screen and (max-width: 400px) {
							margin-top: 3rem;
						}

					    &:nth-child(2){
							margin-top: 0rem;
					    }

					    li {
						    display: block;
							margin-top: 0.3rem;

							@media screen and (max-width: 769px) {
								margin-top: 0rem;
							}

							&.item-227{
								> a{
									background-image: url($images-path/icons/Logo_contact_bianco.svg);
				                    background-size: 20px 20px;

				                    background-position: 8px center;
				                    background-repeat: no-repeat;
				                    padding-left: 35px; 
								}
							}

						    a {
							    color: #FFF;
							    text-decoration: none;

						    	&.hover-red{ &:hover{ color: #F44336;}}
							    &.hover-pink{ &:hover{color: #E91E63;}}
								&.hover-purple { &:hover{color: #9C27B0;}}
								&.hover-deeppurple { &:hover{color: #673AB7;}}
								&.hover-indigo { &:hover{color: #3F51B5;}}
								&.hover-blue { &:hover{color: #2196F3;}}
								&.hover-lightblue { &:hover{color: #03A9F4;}}
								&.hover-cyan { &:hover{color: #00BCD4;}}
								&.hover-teal { &:hover{color: #009688;}}
								&.hover-green { &:hover{color: #4CAF50;}}
								&.hover-lightgreen { &:hover{color: #BBC34A;}}
								&.hover-lime { &:hover{color: #CDDC39;}}
								&.hover-yellow { &:hover{color: #FFEB3B;}}
								&.hover-amber { &:hover{color: #FFC107;}}
								&.hover-orange { &:hover{color: #FF9800;}}
								&.hover-deeporange { &:hover{color: #FF5722;}}
								&.hover-brown { &:hover{color: #795548;}}
								&.hover-grey { &:hover{color: #9E9E9E;}}
								&.hover-bluegrey { &:hover{color: #607D8B;}}
								&.hover-black { &:hover{color: #000000;}}
							   
							}
						}
			    	}  
				}

				&.nav-active {
				    opacity: 1;
				    visibility: visible;
				    height: 100%;
					/*    max-height: 340px;*/
				}

				.languageFlag{
					color: white;
					text-align: center;

					margin-top: 3rem;

					@media screen and (max-width: 400px) {
						margin-top: 1.5rem;
					}			
				}
			}
		}
	}
}





/* Life Video CSS */
.lifeVideo {
	position: relative;

    .video-wrapper{
        margin-top: 0px;

        video{
            width: 100%;
            display: block;

            position: relative;
            z-index: 2;

            background-repeat: no-repeat;
            background-size: 30px 30px;
            background-position: center;

            background-image:  url("ajax-loader.gif")!important;
        }
    }
    
    .container{
        position: absolute;
        left: 50%;

        margin-left: 0 !important;
        transform: translate(-50%, -50%) !important;

        width: 80%;

        top: 35%;
        z-index: 4;

        color: white;
        text-align: center;

        font-size: 18px;        
        line-height: 30px;

        @media screen and (max-width: 768px) {
            width: 100%!important;
            top: 60%!important;

            p{
                line-height: 22px!important;
                margin-top: 15px!important;
            }
            h1{
                font-size: 1.625rem;
                margin-top: 0px;
            }
        }

        h2{
            /*font-size: 36px;*/
        }
    }

    .bg-overlay{
        background:#282c37; 
        opacity:0.5;

        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 3;
    }

    .scroll-arrow {
        cursor: pointer;
        position: absolute;
        /*position: fixed;*/
        left: 50%;
        margin-left: -75px;
        width: 150px;
        z-index: 3;
        height: 30px;
        text-align: center;
        padding: 0;
        box-sizing: border-box;

        bottom: 70px;
        /*bottom: 70px;*/

        @media screen and (min-width:400px) and (max-width:800px) {
            /*bottom: 140px; */
            bottom: 160px;
            display: none;
        }
        @media screen and (max-width: 400px){
            display: none;
        }

        &:hover{
            span{
                display: inline-block;
            }
            img{
                display: none;
            }
        }

        
        &.white {
            color: rgba(255, 255, 255, 0.8);
        }

        img{
            width: 50px;
            cursor: pointer;

            /* https://codepen.io/metagrapher/pen/tgcLl */
            animation: pulse 5s infinite; 
        }

        span {
            display: none;
            font-family: "Avenir Next";
            letter-spacing: 2px;
            font-size: 12px;
            text-align: center;
        }


    }

    &.youtube{
        /*height: 700px;*/
        width: 100%;
        background-size: cover;
        background-color: #000;
        background-repeat: no-repeat;
        background-position: center center;
        text-align: center;
        vertical-align: middle;
        position: relative;
        background-attachment: fixed;
        overflow: hidden;
    }
}


@keyframes pulse{
    0%   {opacity: .2;}
    25%  {opacity: .4;}
    50%  {opacity: .6;}
    75%  {opacity: .8;}
    100% {opacity: 1;}
}


/*.lifeFullscreenBanner{
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 50px 50px 50px;
    width: 100%;
    height: calc(100% - 30px);
    position: fixed;
    background-attachment: fixed;

    color: #fff;
    
	h1{
		text-align: center;
	}
	h4{
		text-align: center;
	}
}*/
.lifeFullscreenBanner{

	position: relative;

	section {
	    padding: 3rem 1.5rem;

	    &.hero{
		    /*-webkit-box-align: stretch;
		    -ms-flex-align: stretch;*/
		    align-items: stretch;
		    /*display: -webkit-box;
		    display: -ms-flexbox;*/
		    display: flex;
		    /*-webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;*/
		    flex-direction: column;
		    /*-webkit-box-pack: justify;
		    -ms-flex-pack: justify;*/
		    justify-content: space-between;

		    background-position: center center;
		    background-repeat: no-repeat;
		    background-size: cover;

		    /* Create the parallax scrolling effect */
  			/*background-attachment: fixed;*/

		    &.is-fullheight {
		        min-height: 100vh;
	    	}
	    	&.is-halfheight {
		        min-height: 50vh;
	    	}
			&.is-large{
	    		@media screen and (min-width: 769px){
					padding-bottom: 18rem;
    				padding-top: 18rem;
	    		}
	    	}
	    	&.is-medium{
	    		@media screen and (min-width: 769px){
					padding-bottom: 9rem;
    				padding-top: 9rem;
	    		}
	    	}
	    	&.is-small{
	    		padding-bottom: 1.5rem;
  				padding-top: 1.5rem;
	    	}
			&.parallax{
	    		background-attachment: fixed;

	    		transition: 0s linear;
    			transition-property: background-position;
    			background-color: #333;
	    	}
			

	    	.hero-body {
				/*-webkit-box-align: center;
				-ms-flex-align: center;*/
				align-items: center;
				/*display: -webkit-box;
				display: -ms-flexbox;*/
				display: flex;

				/*-webkit-box-flex: 1;
			    -ms-flex-positive: 1;*/
			    flex-grow: 1;
			    /*-ms-flex-negative: 0;*/
			    flex-shrink: 0;
			    padding: 3rem 1.5rem;

			    z-index: 1;

				&>.container{
					/*-webkit-box-flex: 1;
				    -ms-flex-positive: 1;*/
				    flex-grow: 1;
				   /* -ms-flex-negative: 1;*/
				    flex-shrink: 1;

				    z-index: 2;

					p{
						/* In desktop view the parameter TEXT WIDTH doesn't affect */
						@media (max-width: 768px) {
							width: 100%!important;
							margin: auto!important;
							float: none!important;
						}
					}

					.title {
					    color: #FFF;
					    /*font-size: 2rem;
					    font-weight: 600;*/
					    line-height: 1.125;
					    word-break: break-word;

					}
					.subtitle {
					    color: #FFF;
					    /*font-size: 1.25rem;
					    font-weight: 400;*/
					    line-height: 1.25;
					    word-break: break-word;
					}
					button{
						margin-top: 1rem;
					}

				.scroll-arrow {
				    /*display: none;*/
				    cursor: pointer;
				    position: absolute;
				    left: 50%;
				    margin-left: -75px;
				    width: 150px;
				    z-index: 10;
				    height: 30px;
				    text-align: center;
				    padding: 0;
				    box-sizing: border-box;

				    bottom: 120px; 

				    @media screen and (max-width: 800px){
						bottom: 140px; 
			    	}

					&:hover{
				    	span{
				    		display: inline-block;
				    	}
				    	img{
				    		display: none;
				    	}
				    }

					
					&.white {
					    color: rgba(255, 255, 255, 0.8);
					}

					img{
						width: 50px;
						cursor: pointer;
	
						/* https://codepen.io/metagrapher/pen/tgcLl */
						animation: pulse 5s infinite; 
					}

					span {
					    display: none;
					    font-family: "Avenir Next";
					    letter-spacing: 2px;
					    font-size: 12px;
					    text-align: center;
					}


				}


				}
			}

			&.hero-gradient-white-zinc{  /*uigradients.com/*/
				background: #ada996; /* fallback for old browsers */

				background: -webkit-linear-gradient(bottom, #ada996, #f2f2f2, #dbdbdb, #eaeaea);
				background: -o-linear-gradient(bottom, #ada996, #f2f2f2, #dbdbdb, #eaeaea);
				background: linear-gradient(to top, #ada996, #f2f2f2, #dbdbdb, #eaeaea); /* Chrome 10-25, Safari 5.1-6 */ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
				
				color: #333;
			}				

			&.hero-gradient-white-clouds{
				background: #ECE9E6;  /* fallback for old browsers */
				background: -webkit-linear-gradient(bottom, #FFFFFF, #ECE9E6);
				background: -o-linear-gradient(bottom, #FFFFFF, #ECE9E6);
				background: linear-gradient(to top, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
			}



			.cover{
			    /*opacity: .3;*/

				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    background: #000;

			    z-index: 0;
				
			}

			.video_background{
				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    /*height: 100%;
			    background: #000;*/
			    z-index: 0;
			    /*
			    @media (max-width: 768px) {
					background-color: red;
					height: 100%;
					width: auto;
					margin-left: -270px;
			    }*/
/*
			    @media screen and (max-width: 769px){
					background-color: red;
					height: 100%;
					width: auto;
					margin-left: -270px;
			    }*/
			}
			


	
			







		}
		&.moon-curve{
			position: relative; /* for the moon */
	    	padding: 0;
	    	
	    	&::after{ 
			background-image: url(../../../modules/mod_lifefullscreenbanner/img/curve-bg-moon.svg);
		    background-size: 100% 100%;
		    content: "";
		    display: block;
		    height: 40px;
		    position: absolute;
		    bottom: -1px;
		    width: 100%;
		    z-index: 1;
		    /*background-color: orange;*/
			}
		}	

	}
	

/*
	.video-container {
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    display: block;
			    height: 100%;
			    z-index: 1;
			   
				.poster {
					img{
						width: 100%;
						bottom: 0;
						position: absolute;
					}
				}
				video {
					
					width: 100%;
    				height: 100%;

					.fillWidth {
						width: 100%;
					}
				}
			}
*/




	.columns {
	    display: flex;
	    overflow: hidden;
	    margin: 1em 0;
	    color: #fff;
	    border: 1px solid #333;
	    align-items: center;
	    width: 100%;

	    &> div {
	        flex: 1;
		}
	
		.left {
		    padding: 1em;
		    background-color: #337ab7;
		    text-align: left;
		}

		.right {
		    padding: 1em;
		    background-color: #5cb85c;
		    text-align: right;    
		}
	}	

}

@keyframes pulse{
	0%   {opacity: .2;}
	25%  {opacity: .4;}
	50%  {opacity: .6;}
	75%  {opacity: .8;}
	100% {opacity: 1;}
}


$images-path: '../../../media/plg_content_imagepopup/css/images';

.imageWrapper{
        margin-bottom:      5px;
        position:           relative;
        
        a{
            .lens{
                width:              26px;
                height:             26px;
                position:           absolute;
                background:         url($images-path/search.png) no-repeat right transparent;
            }
        }

        .imgDesc {
            background-color:           #f2f2f2;
            padding:                    5px 11px 5px 11px;
            line-height:                14px;
            clear:                      both;
            font-size:                  10px;
            color:                      #333;
        }

        @media screen and (max-width: 450px) {
            float:          none!important;
            display:        block!important;
            margin:         auto!important;
            margin-bottom:  1.5rem!important;
            width:          100%!important;

            .imgDesc{
                float:      none!important;
                width:      100%!important;
            }

            a{
                width:          100%!important;
                height:         auto!important;
                img{
                    width:          100%;
                    height:         auto;
                }
                .lens{
                    top:            0!important;
                }
            }
        }

    }